import React from 'react'


export default function DownloadIcon(props) {
    return (
        <svg width="14px" height="21px" viewBox="0 0 14 21" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g  stroke="current" strokeWidth="1" fill="current" fillRule="evenodd" className={props.className}>
                <g  transform="translate(-228.000000, -5569.000000)" fill="current">
                    <g  transform="translate(76.000000, 5452.000000)">
                        <g  transform="translate(152.000000, 117.000000)">
                            <polygon  points="5.754 -0.000733333333 5.754 10.4474333 2.349 6.70193333 0.720666667 8.49126667 6.90566667 15.2947667 13.0906667 8.49126667 11.4623333 6.70193333 8.05733333 10.4474333 8.05733333 -0.000733333333"></polygon>
                            <polygon points="0 20.2563167 13.81 20.2563167 13.81 17.72265 0 17.72265"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
