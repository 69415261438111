import React from 'react'
import { graphql } from 'gatsby';
import RichTextElement from './RichTextElement';
import DownArrow from '../../images/down-arrow.svg'

export default function QuestionAnswerElement({ question, subHeader, answer }) {
    const [expanded, setExpanded] = React.useState(false);


    return (
        <div className="">

            <div   onClick={() => setExpanded(!expanded)} role="button" className="px-0 md:px-2 flex justify-between ">
                <div >
                <h2
                  
                  className="text-knorr text-xl font-bold  "
              >
                  {question}
              </h2>
              <p className="text-xl text-knorr pr-8">{subHeader}</p>
                </div>
  
                <img className="mr-4 transition-all duration-200" style={{ transform: expanded ? 'rotate(180deg)' : '' }} src={DownArrow} alt="Arrow - Open Close Indicator" />
            </div>
            <div
                className="pl-2"
                style={{
                    width: '100%',
                    opacity: 1,
                    height: expanded ? '100%' : 0,
                    maxHeight: expanded ? '1500px' : 0,
                    overflow: 'hidden',
                    transition: expanded ? '1.5s' : '0.5s'
                }}
            >
                <div
                    className=" pt-4 text-primary"
                >
                    <RichTextElement richText={answer} />
                </div>
            </div>
            <div style={{ borderTopWidth: 1 }} className="border-b-1 border-knorr border-opacity-40 border-primary border-t-2 transition mt-4 pt-1"> </div>
        </div>
    )
}

export const QuestionAnswerElementFragment = graphql`
    fragment QuestionAnswerElementFragment on ContentfulQuestionAnswerElement {
        id
        question
        subHeader
        answer {
            json
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
