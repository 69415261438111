import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import Linked from '../general/Linked';

export default function ImageElement({
    caption,
    header,
    name,
    link,
    image,
    fixedImage,
    grid,
    gridClassName = "text-knorr bg-knorr-light text-base px-4 py-2 tracking-wide",
    className = "mx-auto",
    wrapperClassName,
    wrapperStyle }) {


    const GridImage = () => <figure style={wrapperStyle} className={`w-full  ${wrapperClassName}`}>
        {header && <figcaption className={`rounded-t ${gridClassName} hover:text-white hover:bg-knorr`}>
            <div className="uppercase ">
                {header}
            </div>
        </figcaption>}
        <Image image={image} fixedImage={fixedImage} className={`${className} w-full rounded-none `} />
    </figure>


    if (grid) {
        if (link) return <Linked linkTo={link}>
            <GridImage />
        </Linked>
        return <GridImage />
    }




    return (
        <figure style={wrapperStyle} className={`w-full relative ${wrapperClassName}`}>
            {  header && <figcaption className="font-bold text-knorr text-lg">
                <h4>
                    {header}
                </h4>
            </figcaption>}

            <Image image={image} fixedImage={fixedImage} className={className} />
            {  caption && <figcaption className="mt-2 text-center uppercase text-knorr md:text-2xl">
                <h4>
                    {caption}
                </h4>
            </figcaption>
            }
        </figure>
    )
}


const Image = ({ image, fixedImage, className }) => {

    if (image.fluid) {
        return <Img fluid={image.fluid} className={className} alt={image.title} />
    }


    // removed fixed for now as not needed
    // if (fixedImage.fixed) {
    //     return <Img fixed={fixedImage.fixed} className={className} alt={image.title}/>
    // }

    if (!image.fluid && image.file?.url) {

        // Had to write some custom centring to make this match the fluid containers in the gatsby-image
        // might break on other use cases will need to check
        return <div className="flex self-center h-full w-full">
            <img src={image.file.url} alt={image.title} className={`${className} w-full`} />
        </div>
    }

    return () => ""
}


export const ImageElementFragment = graphql`
     fragment ImageElementFragment on ContentfulImageElement {
        caption
        header
        id
        name
        link
        image {
            ...GatsbyFluidImageFragment
        }
        fixedImage: image {
            ...GatsbySmallFixedImageFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
