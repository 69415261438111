import React from 'react'
import LeftArrowGreen from '../../images/left-arrow-green.svg'
import Linked from '../general/Linked';
export default function HomeLink({ className }) {
  return (
    <Linked linkTo="/" className={`text-xl pb-4 lg:py-12 text-knorr ${className}`}>
      <div className="inline-block lg:mt-24 ">
        <img className="float-left w-4 h-4 mt-2 mr-4" src={LeftArrowGreen} alt="" />
        <p className="inline-block float-right mt-px">HOME</p>
      </div>
    </Linked>
  )
}
