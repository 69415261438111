import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import Nav from './Nav';
import Footer from './Footer';
import AOS from 'aos'
import 'aos/dist/aos.css'

import { graphql, useStaticQuery, navigate } from 'gatsby';
import { getPageLinksFromIds, pageIds } from '../../utils/PageDetails';
import StaticMenu from './StaticMenu';
import useSessionStorage from '../../utils/useSessionStorage';
import HomeLink from './HomeLink';
import Popup from '../contentful-other/Popup';
export const HeaderContext = React.createContext(false);
export const PopupContext = React.createContext({});

const Layout = ({ 
    children,
    className,
    heroClassName,
    headerClassName,
    staticMenuLayout,
    staticMenu,
    noFooter,
    noMenu,
    homeLink,
    heroModule,
    noStaticHeader,
    noHero,
    objectPosition,
    arrow=false
}) => {
    const { layoutData } = useStaticQuery(graphql`
    query LayoutStatic {
        layoutData: contentfulSiteMetaData(id: {eq: "b7a76ee6-e571-52b7-9e94-35cb5be5fc96"}) {
            id
            emailPermissions
            navigationLinks {
                ... on ContentfulSubMenu {
                    menuTitle: title 
                    links {
                        title
                        pageId
                    }
                }
                ... on ContentfulPage {
                    title
                    pageId
                }
            }
            footerLinks {
                title
                pageId
            }
            logoFooter {
                ...GatsbySmallFluidImageFragment
            }
            siteTitle
            siteDescription {
                siteDescription
            }
            logo {
                ...GatsbySmallFluidImageFragment
            title
            }
        }
    }
  `)

    const [authenticated] = useSessionStorage("authenticated")

    useEffect(() => {
        if (!authenticated) {
            navigate("/login")
        }
    }, [authenticated])
    // Initialise the Animations for fade up and in
    useEffect(() => {

        AOS.init();
    }, [])



    const staticLayoutClassName = `w-full max-w-screen-md  xl:max-w-screen-lg ${staticMenu && "ml-auto"}`
    // const staticMenuLayoutClassName = "lg:py-24 flex max-w-screen-2xl mx-auto"
    const staticMenuLayoutClassName = "flex  xl:max-w-screen-2xl mx-auto"







    const footerLogo = layoutData?.logoFooter
    const navLogo = layoutData?.logo


    const footerLinks = getPageLinksFromIds(layoutData.footerLinks)



    const [activeHeader, setActiveHeader] = React.useState(false)

    const StaticRef = React.useRef()


    // Popup Carousel State
    const [popupState, setPopupState] = React.useState({
        visible: false,
        content: false
    })

    const showPopup = (content, index) => {
        // console.log(index)
        if (!content) return
        return setPopupState({
            content: content,
            visible: true,
            index
        })
    }
    const hidePopup = () => {
        return setPopupState({
            content: false,
            visible: false,
            index: false
        })
    }

    // Handles the inner array of links in submenus
    const mappedNavLinks = layoutData.navigationLinks.map(
        link => link.__typename === "ContentfulSubMenu" ?
            { linkText: link.menuTitle, routes: getPageLinksFromIds(link.links) }
            : { ...getPageLinksFromIds([link]).find(a => a) })


    return (
        <PopupContext.Provider value={{
            ...popupState,
            showPopup,
            hidePopup
        }}>
            <HeaderContext.Provider value={{
                setActiveHeader: setActiveHeader
            }}>
                <div className="">
                    <Popup visible={popupState.visible} content={popupState.content} index={popupState.index} />
                    <header className={`fixed md:relative w-full ${headerClassName}`} style={{ zIndex: 51 }}>
                        {(!noMenu) && <Nav className={ noHero && "md:hidden"} footerLogo={footerLogo} logo={navLogo} links={mappedNavLinks} />}
                    </header>
                  { !noStaticHeader &&  <header className={`md:fixed top-0 md:block hidden bg-cta h-20 z-10  w-full ${headerClassName}`} >
                        {!noMenu && <Nav footerLogo={footerLogo} logo={navLogo} links={mappedNavLinks} hover={true}/>}
                    </header> } 
                    <main className={`text-knorr ${heroClassName}`}>
                        {heroModule && heroModule.map(({ Module, id }) => <Module objectPosition={objectPosition} key={id} arrow={arrow} />)}
                        <div ref={StaticRef} style={{ zIndex: 9 }} className={`lg:hidden no-scroll-bars bg-white  fixed top-11 md:top-20 overflow-x-scroll shadow-xl ${!staticMenu && "hidden"}`}>
                            <nav className="h-full pt-4 pb-3 px-4  w-full text-knorr" >
                                {staticMenu && <StaticMenu staticRef={StaticRef.current} activeHeader={activeHeader} />}
                            </nav>
                        </div>
                        <div className={`${staticMenuLayout && staticMenuLayoutClassName} `}>
                            <div className={`hidden lg:block`}>
                                {staticMenuLayout && <nav className="top-0 lg:sticky pt-8 -mt-8 md:mt-0 pl-2 pr-4  ">
                                    {homeLink && <HomeLink className="" />}
                                    {staticMenu && <StaticMenu activeHeader={activeHeader} />}
                                </nav>}
                            </div>

                            <div className={`
                                ${className}  
                                ${staticMenuLayout && staticLayoutClassName} 
                                flex flex-col 
                   
                                ${heroModule?.length === 0 &&  "pt-12" }`
                                }>
                                {homeLink && <HomeLink className="flex mt-8 mb-6 lg:hidden" />}

                                {children}
                            </div>
                        </div>
                    </main>
                    {!noFooter && <Footer logo={footerLogo} links={footerLinks} />}
                </div>
            </HeaderContext.Provider>
        </PopupContext.Provider>
    )
}

Layout.propTypes = {

}

export default Layout
