import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function PackagingStage({ header, description, image, className }) {
    return (
        <div className={className}>
            <Img className="mx-auto " fluid={image.fluid} />

        </div>
    )
}



export const PackagingStageFragment = graphql`
     fragment PackagingStageFragment on ContentfulPackagingStage {
        id
        header
        description {
            text: description
        }
        image {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
