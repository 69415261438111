import Slider from "react-slick";
import React from 'react'
import useElements from '../../utils/useElements';
import CarouselSlide from './CarouselSlide';
import LeftChevron from '../../images/left-chevron.svg'
import DownArrow from '../../images/down-arrow-white.svg'
import GreenDownArrow from '../../images/down-arrow.svg'
import Img from 'gatsby-image'


export default function InfiniteCarousel({ name, slides = [], images = [] }) {


    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)

    const settings = {
        className: "center",
        lazyLoad: true,
        centerMode: true,
        centerPadding: "250px",
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: false,
        beforeChange: (oldIndex, newIndex) => beforeChange(oldIndex, newIndex),
        nextArrow: <NextArrow alt={!!images} />,
        prevArrow: <PrevArrow alt={!!images} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: "120px",
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: "50px",

                }
            },
            {
                breakpoint: 450,
                settings: {
                    centerPadding: "0px",
                }
            }
        ]
    };
    const beforeChange = (oldIndex, newIndex) => {
        return setCurrentSlideIndex(newIndex)
    }


    return (
        <div id="infinite-slider" 
        className={`infinite-carousel py-6 px-4 md:px-0 relative bg-knorr ${images ? "bg-opacity-25 py-16 md:py-20 lg:py-28 xl:py-32" : "bg-opacity-95"} flex flex-col justify-center `}>
            {slides && <p className="mb-8 md:mb-12 text-center text-white">{name}</p>}
            <Slider {...settings} className="infinite-slider">
                {slides && slides.map((slide, index) =>
                    <CarouselSlide
                        className={`${index !== currentSlideIndex ? "opacity-20  scale-90 " : "opacity-100 scale-100"} transform transition duration-200`}
                        key={slide.header}
                        {...slide}
                    />)}
                {images && !slides && images.map(({ image, ...restOfImage}, index) =>
                    <div key={image.title}>
                        <figure
                            style={{ display: "flex !important", maxWidth:450, minHeight: 400}}
                            className={`${index !== currentSlideIndex ? "opacity-20  scale-90 " : "opacity-100 scale-100"} transform transition duration-200 max-w-xl mx-auto flex flex-col relative h-full justify-center`}>
                            <Img className="w-full h-full mx-auto" fluid={image.fluid} alt={image.title} />
                        </figure>
                    </div>

                )}
            </Slider>
          {images && <div className={`mt-8 text-xs text-center ${images[currentSlideIndex].caption ? "opacity-100" : "opacity-0"} `}>{images[currentSlideIndex].caption || " a"}</div> } 
        </div>
    );
}

export function PrevArrow(props) {
    return <Arrow {...props} className={`
        -ml-2 md:ml-10 
        ${props.popup && "bg-opacity-0 transform scale-150"
    }`} />
}
export function NextArrow(props) {
    return <Arrow {...props} 
        reverse={true} 
        className={`
        right-0 -mr-2 md:mr-10  
        ${props.popup && "bg-opacity-0 transform scale-150"}
        `}/>
}


const Arrow = ({ onClick, className, reverse = false, alt=false }) => { 


  const [ hover, setHover ] =  React.useState(false)
return <div
    onMouseEnter={() =>setHover(true)}
    onMouseLeave={() =>setHover(false)}
    className={`absolute  w-10 h-10 text-knorr top-1/2 -mt-5 flex justify-center cursor-pointer rounded-full ${!alt ? "bg-white" : "bg-knorr"} hover:bg-knorr-light hover:text-knorr ${className}`}
    onClick={onClick}
    style={{ zIndex: 9 }}
>
   { alt && !hover && <img className={`transform  ${reverse ? "pr-1 -mt-1  -rotate-90 rota" : "pt-1 rotate-90"}`} src={DownArrow} alt="Left Chevron" />} 
   { alt && hover && <img className={`transform w-7  ${reverse ? "pr-1 -mt-1  -rotate-90 rota" : "pt-1 rotate-90"}`} src={GreenDownArrow} alt="Left Chevron" />} 
   {!alt && <img className={`${reverse ? "pr-1 transform rotate-180" : "pr-1"}`} src={LeftChevron} alt="Left Chevron" />} 
</div>}