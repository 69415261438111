export const pageIds = [
    "brand-vision",
    "our-brand-equities",
    "photography-principles",
    "contact-faqs",
    "design-principles",
    "assets",
    "multi-language",
    "packaging-in-practise",
    "typography",
    "colour-palette",
    "on-pack-claims",
    "knorr-logo",
    "recycling-guide",
    "homepage",
    "our-product-pillars"
]
export const pageRoutes = {
    "brand-vision": "knorrbrandvision",
    "our-brand-equities": "ourbrandequities",
    "photography-principles": "photography",
    "contact-faqs": "faqsandcontacts",
    "assets": "downloadassets",
    "multi-language": "404",
    "packaging-in-practise": "packaginginpractise",
    "typography":"typography",
    "colour-palette": "ourcolourpalette",
    "on-pack-claims": "onpackclaims",
    "knorr-logo": "theknorrlogo",
    "recycling-guide": "404",
    "homepage": "",
    "our-product-pillars": "ourproductpillars"
}

export const getPageLinksFromIds = (arrayOfPages=[]) => {
    return arrayOfPages.map(page => ({
        route: pageRoutes[page.pageId],
        linkText: page.title
    }))
}