import React from 'react'

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { RichInlineImage, RichInlinePost, Hr, Quote} from './Components'
import Linked from '../general/Linked';





const createSpanFromMatches = (matches, text, restProps={}) => {
  const content = text.split(`${matches[0]}[${matches[2]}]`)
  return [...new Set(content)] // get the unique values / avoid ["", ""] - when there are no other parts of text
          .map(text => text === "" ?  // map over the unique values to replace that which was split
            <span {...restProps} style={{color: `${matches[2]}`}} >{matches[1]}</span>  // return the element with the colour
            : text) // or return the text
}


const createBreakFromString = (text) => {
  const content = text.split(/\n/)
  return content.flatMap( (text, index) => (index > 0 && index < content.length) ?[ <br />, text ] : text)
}


const hasBreak = (string) => {
  if (typeof string !== "string") return false
  const regex = RegExp('\n');
  const hasBreak = regex.test(string)
  return hasBreak
}


export const addColour = (children, log=false, node) => {

  const mappedChildren = children.flatMap(child => {
    
    if (typeof child === "string") {
        const matches = child.match(/\((.+)\)(?=\[(#\w+)\])/)
        if (matches) {
          return createSpanFromMatches(matches, child)
        }
        if (hasBreak(child)) {
          const replaced = createBreakFromString(child)
          return replaced
        } 
    }
    if (typeof child === "object") {
      const content = child.props?.children
      const className= child.props?.className
      const matches = typeof content === "string" && content.match(/\((.+)\)(?=\[(#\w+)\])/)

      if (matches) {
        return createSpanFromMatches(matches, content, { className } )
      }


      if (hasBreak(child)) {
        const replaced = createBreakFromString(child)
        return replaced
      } 


    }
    return child
  })

  return mappedChildren
}



const options = {
  renderMark: {
    [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
    [MARKS.CODE]: text => <pre className="text-gray-600 bg-gray-300 px-2 py-1 rounded mb-0" style={{ fontFamily: 'monospace' }}>  {text}</pre>
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h2>{addColour(children)}</h2>,
    [BLOCKS.HEADING_2]: (node, children) => <h3>{addColour(children)}</h3>,
    [BLOCKS.HEADING_3]: (node, children) => <h4 >{addColour(children)}</h4>,
    [BLOCKS.HEADING_4]: (node, children) => <h5>{addColour(children)}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{addColour(children)}</h6>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="">{addColour(children)}</p>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{addColour(children)}</Quote>,
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.LIST_ITEM]: (node, children) => <li className="">{addColour(children)}</li>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="">{addColour(children)}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className="">{addColour(children)}</ol>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {

      const photo = node.data.target.fields.photo || false
      const isInlinePhoto = node.data.target.sys.contentType.sys.contentful_id === "inlinePostPhoto" || false
      const isPost = node.data.target.sys.contentType.sys.contentful_id === "post" || false
      if (isInlinePhoto) {
        const image = photo["en-US"].fields.file["en-US"]
        const alt = photo["en-US"].fields?.title?.["en-US"]
        return <RichInlineImage image={image} alt={alt || false} className="max-w-3xl mx-auto " />
      }

      if (isPost) {
        return <RichInlinePost node={node} />
      }
      return ""

    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (!node.data.target || !node.data.target.fields.file) return ""
      if (node.data.target.fields.file["en-US"].contentType.includes('image')) {
        const image = node.data.target.fields.file['en-US']
        const alt = node.data.target.fields?.title?.["en-US"]
        return <RichInlineImage image={image} alt={alt}/>
      }
    },
    [INLINES.HYPERLINK]: (node, children) => <Linked linkTo={node.data.uri} className="text-highlight underline">{children} </Linked>,
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.sys.contentType.sys.contentful_id === "inlinePostPhoto") {
        // This stupid object for determining if there is an image at the end
        if (node.data.target.fields.photo["en-US"].fields.file["en-US"].contentType.includes('image')) {
          const image = node.data.target.fields.photo["en-US"].fields.file["en-US"]
          return <RichInlineImage image={image} className="max-w-3xl my-8 mx-auto" />
        }


      }
    },
  },
}
const RichText = React.forwardRef(({ text, className }, ref) => (
  <div  ref={ref} className={className}>
    {documentToReactComponents(text, options)}
  </div>
))

export default RichText

