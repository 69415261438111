import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'

export default function StaticMenu({ activeHeader, staticRef }) {



    const [headers, setHeaders] = React.useState([])





    const pathName = typeof window !== "undefined" && window.location?.pathname
    React.useEffect(() => {

        const nodeList = document.querySelectorAll('[data-menu="true"]')
        let nodes = []
        nodeList.forEach(node => {
            // headerRefs[node.dataset?.text || node.textContent] = React.useRef()
            nodes.push({
                value: node.dataset?.text || node.textContent,
                id: node.id,
                subHeader: !!node.dataset?.sub
            })
        })
        setHeaders(nodes)
    }, [])

   
    return (
        <>
            <div className="md:hidden  text-knorr text-xl">
                <nav 
                className="flex min-w-xxxs  w-screen" >
                    {headers && headers.map((header, index) =>{

                        if (activeHeader === header.id && staticRef) {
                            const offsetLeft = document.querySelector(`#${header.id}-link`)?.offsetLeft
                            const scrollObject = {
                                top: 0, 
                                left:  offsetLeft - 20,
                                behavior: 'smooth'
                            }
                            console.log(scrollObject)
                            staticRef.scrollTo(scrollObject)
                        }
                     return <AnchorLink
                            key={`${header.id}-${index}`}
                           
                            className={`${activeHeader === header.id ? "font-bold" : "font-normal"} whitespace-no-wrap block  hover:text-knorr-light`}
                            to={`/${pathName.replace(/\//, "")}#${header.id}`} >
                                <span className="pr-12" id={`${header.id}-link`}  >   {header.value}
                                </span>
                         
                        </AnchorLink>})}
                </nav>
            </div>
            <div className="hidden md:block lg:pt-12 text-knorr text-xl">
                <nav 
                className="flex pr-8 lg:pl-4 xl:pl-0 xl:w-56 min-w-xxxs lg:flex-col lg:space-x-0 lg:space-y-2 " >
                    {headers && headers.map((header, index) =>{

                        if (activeHeader === header.id && staticRef) {
                            const offsetLeft = document.querySelector(`#${header.id}-link`)?.offsetLeft
                            const scrollObject = {
                                top: 0, 
                                left:  offsetLeft - 20,
                                behavior: 'smooth'
                            }
                            console.log(scrollObject)
                            staticRef.scrollTo(scrollObject)
                        }
                     return <AnchorLink
                            key={`${header.id}-${index}`}
                           
                            className={`${activeHeader === header.id ? "font-bold" : "font-normal"} whitespace-no-wrap lg:whitespace-normal hover:text-knorr-light ${header.subHeader && "lg:pl-6"} `}
                            to={`/${pathName.replace(/\//, "")}#${header.id}`} >
                                <span className="mx-4 lg:mx-0" id={`${header.id}-link`}  >   {header.value}
                                </span>
                         
                        </AnchorLink>})}
                </nav>
            </div>
        </>
    )
}
