import React from 'react'
import { graphql } from 'gatsby';
import { toKebabCase } from '../../utils/General';
import IsVisible from 'react-visibility-sensor';
import { HeaderContext } from '../layout/Layout';
import { addColour } from '../rich-text/RichText';

export default function HeaderElement({ text, noMenu=false, className = "text-5xl md:text-7xl lg:text-8xl text-knorr", ...restProps }) {

    const headerId = toKebabCase(text)
    const { setActiveHeader } = React.useContext(HeaderContext);

    const handleChange = (change) => {
        if (!noMenu) {
            return setActiveHeader(headerId)
        }
        
    }

    return (
        <IsVisible onChange={(change) => handleChange(change)} offset={{bottom: 100}}>
            <h2  id={headerId} data-menu={`${!noMenu ?  "true" : "false" }`} className={`font-normal leading-tight  ${className} `}>
                {addColour([text])}
            </h2>
        </IsVisible>

    )
}


export const HeaderElementFragment = graphql`
    fragment HeaderElementFragment on ContentfulHeaderElement {
        text
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
