import React from 'react'
import { graphql } from 'gatsby';
import useElements from '../../utils/useElements';

export default function TwoImagesElement({images, name}) {



    const ImageElements = useElements(images, {
        imageElement: {
            // gridClassName: "text-lg text-knorr font-bold ",
            // grid: false, 
            // className: "uppercase"
        }
      
    })

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
            <ImageElements />
        </div>
    )
}


export const TwoImagesElementFragment = graphql`
   fragment TwoImagesElementFragment on ContentfulTwoImagesElement {
        id
        name
        images {
            ...ImageElementFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
