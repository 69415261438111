export const mapPageData = (pageData) => {

    if (!pageData) return {
        modules: []
    }
    const id = pageData?.sys?.contentType.sys.id

    return {
        modules: mapContentfulData(pageData.modules),
        title: pageData.title,
        id,
        pageId: pageData.pageId
    }
}



const mapContentfulData = (data) => {
    const mappedData = data.map( object => {
        const { sys, __typename, id, ...restOfObject} = object
        // console.log(restOfObject)
        return {
        type: getTypeFromSys(object.sys),
        _id: id,
        ...restOfObject
    }
})
    return mappedData
}


export const getTypeFromSys = (sys) => {
    if (!sys) return false
    return sys.contentType?.sys.id
}