import React from 'react'
import { graphql } from 'gatsby';

export default function PreHeaderElement({ text, className="font-semibold text-knorr md:text-2xl md:font-bold " }) {

    return (<>
        { text && <h5 className={`uppercase  ${className}`}>{text}</h5> }
    </>) 
}


export const PreHeaderElementFragment = graphql`
    fragment PreHeaderElementFragment on ContentfulPreHeaderElement {
        id
        text
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
