import React from 'react'


export default function BurgerMenu(props) {
    return (
        <svg {...props} width="28px" height="22px" viewBox="0 0 28 22" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g stroke="current " strokeWidth="1" fill="current" fillRule="evenodd" strokeLinecap="square" >
                <g transform="translate(-1372.000000, -29.000000)" stroke={props.colour} strokeWidth="2">
                    <g id="Group-12" transform="translate(1373.000000, 29.000000)">
                        <line x1="0" y1="11" x2="26" y2="11" id="Line-Copy"></line>
                        <line x1="0" y1="1" x2="26" y2="1" id="Line"></line>
                        <line x1="0" y1="21" x2="26" y2="21" id="Line-Copy-2"></line>
                    </g>
                </g>
            </g>
        </svg>
    )
}
