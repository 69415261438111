import React from 'react'
import { graphql } from 'gatsby';
// import RightArrow from '../../images/right-arrow.svg'
import RightArrow from '../svgs/RightArrow';
import Linked from '../general/Linked';


export default function ButtonElement({ text, alt, className, link, spread=false }) {

    const fixedLink = link && link.replace(/^\//, "")

    return (
        <Linked linkTo={`/${fixedLink ? fixedLink : ""}`} className="block">
            <button 
            className={`${!alt ? `bg-knorr text-white` : "text-knorr bg-white"}
                ${spread ? "hover:bg-knorr hover:bg-opacity-20": "hover:bg-knorr-light hover:text-knorr"}
                uppercase rounded justify-center md:justify-start  min-w-full md:min-w-0  px-4 py-2 flex ${className}`}>
                {text}

                <RightArrow className={`ml-2 self-center fill-current `} />
            </button>
        </Linked>
    )
}


export const ButtonElementFragment = graphql`
    fragment ButtonElementFragment on ContentfulButtonElement {
        id
        text
        link
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
