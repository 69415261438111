import React from 'react'
import { graphql } from 'gatsby';
import RichText from '../rich-text/RichText';

export default function RichTextElement(props) {
    const { richText, className="rich-text" } = props

    return (
           <RichText text={richText.json} className={className}/>
    )
}


export const RichTextElementFragment = graphql`
    fragment RichTextElementFragment on ContentfulRichTextElement {
        id
        name
        richText: text {
            json
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
