import React from 'react'


export default function RightArrow(props) {
    return (
        <svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g  stroke="current" strokeWidth="1" fill="current" fillRule="evenodd" className={props.className}>
                <g  transform="translate(-239.000000, -6114.000000)" fill="current">
                    <g  transform="translate(40.000000, 5683.000000)">
                        <g  transform="translate(43.000000, 419.000000)">
                            <polygon  transform="translate(165.000000, 19.500000) rotate(-90.000000) translate(-165.000000, -19.500000) " points="163.750732 11 163.750732 23.3294245 159.782176 19.4641207 158 21.1822124 164.999908 28 172 21.1822124 170.218008 19.4641207 166.249452 23.3294245 166.249452 11"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
