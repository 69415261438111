import React from 'react'
import { graphql } from 'gatsby';
import useElements from '../../utils/useElements';
import Img from 'gatsby-image'
import PreHeaderElement from '../contentful-elements/PreHeaderElement';

export default function CarouselSlide({ preHeader, header, description, link, image, className }) {



    const DesktopLinkElement = useElements(link, {className: "hidden md:flex max-w-content"})
    const MobileLinkElement = useElements(link, {className: "md:hidden mt-4", alt: true})
    return (
        <div className="h-full" className={className}>
            <div className="bg-white max-w-2xl mx-auto grid md:grid-cols-2 rounded">
                <div className="p-6 flex-col flex justify-between">
                    {preHeader && <PreHeaderElement text={preHeader} className="text-sm font-bold "/> } 
                    <h3 className="text-3xl">{header}</h3>
                    { description?.text && <p className="hidden md:block md:mb-4">{description.text}</p> }
                    <DesktopLinkElement />
                </div>
                <div className="h-88 md:h-104  ">
                    <Img className="h-full rounded-b md:rounded-r" fluid={image.fluid} alt={image.title}/>
                </div>

            </div>
            <MobileLinkElement />
        </div>
    )
}


export const CarouselSlideFragment = graphql`
   fragment CarouselSlideFragment on ContentfulCarouselSlides {
        id
        preHeader
        header
        description {
            text: description
        }
        link {
            ...ButtonElementFragment
        }
        image {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
