import React from 'react'



// Returns all the components in a React Fragments 
// cleans up code when using UseElements
export default function ElementWrapper({elements}) {
    const isArray = Array.isArray(elements)

    const elementsToRender = isArray && elements.map( 
            (data, index) => <data.Element key={data.id + `${index}`} />
        )

    return (
        <>
         { elementsToRender }
         { !isArray && <elements.Element /> }
        </>
    )
}
