import React from 'react'
import { graphql } from 'gatsby';
import DownloadIcon from '../svgs/DownloadIcon';
import LinkIcon from '../svgs/LinkIcon';

export default function AssetElement({ name, file, fileLink, image, updatedAt, alt, showImage, noDate = false, className=" hover:text-knorr-light", altHover, preText = "" }) {

    const [ hovered, setHovered ] = React.useState(false)


    // Need to add download link or file download

    const hoverClass = altHover ? "text-knorr-alt" : "text-knorr-light"

    const fileToDownload = fileLink ? fileLink : file?.data.url ? file?.data.url : "/404"
    if (image && showImage) {
        return (
            
            <a 
            href={fileToDownload}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4  py-3 leading-tight text-left bg-knorr bg-opacity-10 hover:bg-white  text-knorr md:px-2">
                <img className="mb-4 md:mb-4" src={image.file.url} alt={image.title} />
                <div className="flex flex-col justify-between">
                    <div>
                    <DownloadIcon className={`mb-4 fill-current self-center ${alt ? "text-white" : "text-knorr"}`} />

                        <p className="font-bold">
                            {name}
                        </p>
                        <p className="">Download now</p>
                    </div>
                </div>
            </a>
        )
    }
    return <a
        href={fileToDownload}
        target="_blank"
        onMouseEnter={ () => !hovered && setHovered(true)}
        onMouseLeave={ () => hovered && setHovered(false)}
        rel="noopener noreferrer"
        className={`asset-element max-w-md text-left w-full border-t border-b pt-4 pb-3 -mt-px grid  relative grid-cols-7 md:grid-cols-5 
        ${alt ?
                 `border-white ${hovered ? hoverClass : "text-white"}` :
                  `border-knorr ${hovered ? hoverClass : "text-knorr"}`} ${className}`}
    >
        <div className={"inline-block col-span-6 md:col-span-4"}>
            <h5 className="text-2xl font-normal leading-tight">{preText}{name}</h5>
            {!noDate && <p className="font-thin">Last updated {updatedAt}</p>}
        </div>

        <div className="flex justify-end  md:pr-4 text-white fill-current ">
            {fileLink ? <LinkIcon width="32px" 
                className={`fill-current self-center 
                ${alt ? hovered ? hoverClass : "text-white" : hovered ? hoverClass :"text-knorr"}`} /> : <DownloadIcon 
                className={`fill-current self-center mr-2  ${alt ? hovered ? hoverClass : "text-white" : hovered ? hoverClass :"text-knorr"}`}  />}
        </div>

    </a>


}


export const AssetElementFragment = graphql`
    fragment AssetElementFragment on ContentfulAssetElement {
        id
        name
        file {
            data: file {
                fileName
                url
            }
        }
        fileLink
        image {
            ...SVGImageFragment
        }
        updatedAt(formatString: "Do MMMM YYYY")
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
