import Slider from "react-slick";
import React from 'react'
import Img from 'gatsby-image'
import CarouselIcon from '../../images/carousel-icon.svg';
import { PopupContext } from "../layout/Layout";

export default function PopupCarousel({ images = [] }) {

    // Total images to show
    const { showPopup } = React.useContext(PopupContext);

    return (
        <section className="">
            <CarouselIconImage className="w-full" image={images[0].image} onClick={() => showPopup(images, 0)} />
            <div className="grid md:grid-cols-3 gap-x-6 gap-y-6 mt-6 md:mt-10 ">
                {images.map((image, index) => index > 0 && index < 4 ? <figure key={image.title + `${index}`} onClick={() => showPopup(images, index)}><Img className="w-full hidden lg:block h-full" fluid={image.image.fluid} /></figure> : "")}
            </div>
        </section>
    );
}


const CarouselIconImage = ({ image, className, wrapperClassName, onClick }) => {
    return <figure onClick={() => onClick()} className={`relative ${wrapperClassName}`}>
        <Img className={`${className}`} fluid={image.fluid} />
        <img className="absolute bottom-0 left-0 ml-4 mb-4" src={CarouselIcon} alt="This image leads to a carousel indiciator" />
    </figure>
}

