import React from 'react'
import { PopupContext } from '../layout/Layout';
import Slider from "react-slick";
import Img from 'gatsby-image'
import { PrevArrow, NextArrow } from './InfiniteCarousel';
import CrossIcon from '../../images/cross-icon.svg'
export default function Popup({ visible, content, index }) {

    const CarouselRef = React.useRef()

    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(index)


    const { hidePopup, ...rest } = React.useContext(PopupContext);



    React.useEffect(() => {
        if (currentSlideIndex !== index) {
            if ( CarouselRef.current) CarouselRef.current.slickGoTo(index)
            return setCurrentSlideIndex(index)
        }

    },[index])



    const settings = {
        lazyLoad: true,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: false,
        beforeChange: (oldIndex, newIndex) => beforeChange(oldIndex, newIndex),
        nextArrow: <NextArrow popup alt={false} />,
        prevArrow: <PrevArrow popup alt={false} />,
    };
    const beforeChange = (oldIndex, newIndex) => {
        if (newIndex >= 0) setCurrentSlideIndex(newIndex)
    }


    const handleClosePopup = () => {

        setCurrentSlideIndex(0)
        return hidePopup()
    }

    return (
        <div className={`${visible ? "scale-100  bg-knorr-alt opacity-100" : "scale-95 opacity-0"} transform transition duration-300  w-screen h-screen fixed text-knorr `} style={{ zIndex: visible ? 9999 : -9999 }}>
            <button className="absolute top-0 pt-4 pr-4 right-0 md:pr-8 md:pt-6 text-4xl " onClick={() => handleClosePopup()}>
               <img src={CrossIcon} alt="Close" />
            </button>

            <div className="h-full flex flex-col justify-center">
                <div className="">
                    <Slider ref={CarouselRef} {...settings} className="infinite-slider"> 
                        {content && content.map(({ image }, index) =>
                            <div key={image.title}>
                                <figure
                                    style={{ display: "flex !important" }}
                                    className={`transform transition duration-200 px-4  md:max-w-2xl lg:max-w-3xl xl:max-w-4xl mx-auto flex flex-col relative h-full`}>
                                    <Img 
                                        className="w-full h-full mx-auto self-center" 
                                        fluid={image.fluid} alt={image.title} 
                                    />
                                </figure>
                            </div>
                        )}
                    </Slider>
                    {content && currentSlideIndex >= 0 && 
                    <p 
                    className={`mt-8 text-lg md:text-xl lg:text-2xl text-center 
                        ${content[currentSlideIndex].caption ? "opacity-100" : "opacity-0"} 
                    `}>
                        {content[currentSlideIndex]?.caption || "TBV"}
                        </p>}
                </div>
            </div>


        </div>
    )
}
