export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: {
                value: "",
                ...item,
            },
        };
    }, initialValue);
};




// Encode the form data for URL submission
export const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

// Custom validation of email function
export const ValidateEmail = (input) => {
    // Retrieve the email from previously submitted forms
    // const item = getLocalStorage("email");
    let item = ""
    if (item === input) {
        // return error message if it exists
        return "This email address has already been used."
    } else {
        return input && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input)
    }
}


export const toKebabCase = str =>
    str && str
    .replace(/[0-9]/,"")
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');