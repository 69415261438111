import React from 'react'
import { graphql } from 'gatsby';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfiniteCarousel from '../contentful-other/InfiniteCarousel';
import PopupCarousel from '../contentful-other/PopupCarousel';

export default function CarouselElement(props) {

    return (
        props.carouselType[0] === "Infinite Carousel" ? <InfiniteCarousel {...props} /> : <PopupCarousel {...props}/>
    );
}


export const CarouselElementFragment = graphql`
    fragment CarouselElementFragment on ContentfulCarouselElement {
        id
        name
        carouselType
        images {
            ...ImageElementFragment
        }
        slides {
            ...CarouselSlideFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
