import React from 'react'
import PropTypes from 'prop-types'
import Linked from '../general/Linked';
import Img from 'gatsby-image';

const Footer = ({ logo={}, links=[] }) => {


    return (
        <footer className="pb-2 text-white  bg-knorr border-t border-cta" >
            <div className="flex flex-col max-w-screen-xl px-8 py-24 mx-auto lg:flex-row md:px-12">
                <aside className="self-center mb-4 md:mx-auto lg:mx-0 lg:mb-0">
                    <Img className="w-20 lg:w-24" fluid={logo.fluid}/>
                </aside>
                <div className="flex flex-col flex-wrap justify-center w-full space-y-2 lg:-ml-24 md:flex-row md:space-x-6 lg:space-x-12 md:space-y-0">
                { links && links.map(page => <Linked key={page.route} className="self-center hover:text-knorr-alt" linkTo={`/${page.route}`}>{page.linkText}</Linked>)}

                </div>
            </div>
            <p className="text-center opacity-20 ">© {`${new Date().getFullYear()}`} Knorr</p>
        </footer>
    )
}

Footer.propTypes = {

}

export default Footer
