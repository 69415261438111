import React, { useState } from 'react'
import UseBodyLock from '../../hooks/useBodyLock';
import Img from 'gatsby-image';
import NavBars from '../../images/navbars.svg'
import Linked from '../general/Linked';
import DownArrow from '../../images/down-arrow.svg'
import DownArrowWhite from '../../images/down-arrow-white.svg'
import BurgerMenu from '../svgs/BurgerMenu';




const Nav = ({ logo, footerLogo, links = [], className, hover }) => {

    const pathName = typeof window !== "undefined" && window.location?.pathname

    const [menuOpen, setMenuOpen] = useState(false)


    // Locks the scrolling on the body when menu is open
    UseBodyLock(menuOpen)

    const [colour, setColour] = useState("#ffffff")


    return (
        <>
            <nav className={`relative ${className}`}>
                <div className="z-30 flex justify-between w-full px-4 md:px-8 bg-cta md:bg-transparent md:absolute">
                    <div className="relative w-24 h-12 md:h-20">
                        <div className="absolute -mt-px top-3 lg:left-20 lg:mt-0 lg:top-3">
                            <Linked linkTo="/" >
                                <Img className="w-9 md:w-16 lg:w-32" fluid={logo.fluid} />
                            </Linked>
                        </div>

                    </div>
                    <button
                        onClick={() => setMenuOpen(true)}
                        className={`relative self-center font-normal lg:right-20 text-knorr md:text-white ${hover && "hover:text-knorr"} `}
                        onMouseEnter={() => setColour("#02793d")}
                        onMouseLeave={() => setColour("#ffffff")}
                    >
                        MENU
                        {   hover ? <>
                            <div className="hidden md:inline-block">
                                <BurgerMenu className="inline-block w-6 ml-4 -mt-1" colour={colour} />
                            </div>
                        </> : <> <div className="hidden md:inline-block">
                                <BurgerMenu className="inline-block w-6 ml-4 -mt-1" colour={"#ffffff"} />
                            </div> </>}

                        <div className="inline-block md:hidden">
                            <BurgerMenu className="inline-block w-6 ml-4 -mt-1" colour="#02793D" />
                        </div>

                    </button>
                </div>
            </nav>
            <div className={`${!menuOpen ? "-right-full" : "right-0"} shadow-lg overflow-y-scroll transition-all duration-300 fixed px-8 pt-2 lg:py-8 h-screen max-w-md lg:max-w-xl w-full ml-auto bg-knorr lg:bg-white top-0 z-40 text-xl text-white lg:text-knorr `}>
                <div className="flex justify-between text-2xl ">
                    <aside className="pt-2">
                        <Linked linkTo="/"><Img className="w-8 -mt-px lg:hidden" fluid={footerLogo.fluid} /></Linked>
                    </aside>
                    <button className="" onClick={() => setMenuOpen(false)}>
                        X
                    </button>
                </div>
                <ul className="mt-4 space-y-8">
                    {links.map(link => link.routes ? <Menu onClick={() => setMenuOpen(false)} key={link.linkText} links={link} /> : <NavLink onClick={() => setMenuOpen(false)} key={link.route}  {...link} />)}
                </ul>
            </div>
        </>
    )
}



const NavLink = ({ route, linkText, line = true, onClick }) => <li onClick={onClick} className={`${line && "border-b border-white lg:border-knorr border-opacity-40 lg:border-opacity-25"} hover:text-knorr-light my-2 pb-2`}><Linked linkTo={`/${route}`}>{linkText}</Linked></li>

const Menu = ({ links, onClick }) => {

    const [openMenu, setOpenMenu] = React.useState(true)
    return <li className="relative">
        <div
            onClick={() => setOpenMenu(!openMenu)}  // client requested to stay open at all times
            className="flex justify-between pb-2 border-b border-white cursor-pointer lg:bg-white lg:border-knorr border-opacity-40 lg:border-opacity-25 hover:text-knorr-light">
            <p >{links.linkText}</p>

            <img
                className="hidden w-4 mr-4 text-white transition-all duration-200 fill-current lg:block"
                style={{ transform: openMenu ? 'rotate(180deg)' : '' }}
                src={DownArrow}
                alt="Arrow - Open Close Indicator"
            />
            <img
                className="w-4 mr-4 text-white transition-all duration-200 fill-current lg:hidden"
                style={{ transform: openMenu ? 'rotate(180deg)' : '' }}
                src={DownArrowWhite} alt="Arrow - Open Close Indicator"
            />
        </div>
        <div
            style={{
                width: '100%',
                opacity: 1,
                height: openMenu ? '100%' : 0,
                maxHeight: openMenu ? '1000px' : 0,
                overflow: 'hidden',
                transition: openMenu ? '1.5s' : '0.5s'
            }}
        >
            <ul className={`space-y-4 pl-8 text-lg pt-4 pb-5 border-b border-knorr border-opacity-40`}>
                {links.routes.map(link => <NavLink onClick={onClick}  key={link.route} line={false} {...link} />)}
            </ul>
        </div>
    </li>
}

Nav.propTypes = {

}

export default Nav
