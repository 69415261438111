import React from 'react'
import { graphql } from 'gatsby';
import useElements from '../../utils/useElements';

export default function GridImageElement({ name, images }) {


    const ImageList = useElements(images, { grid: true})


    return (
        <div className="grid md:grid-cols-2 gap-4">
            <ImageList />
        </div>
    )
}


export const GridImageElementFragment = graphql`
   fragment GridImageElementFragment on ContentfulGridImageElement {
        id
        name
        images {
            ...ImageElementFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
