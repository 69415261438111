import React from 'react'
import { graphql } from 'gatsby';

export default function ListElement({items, className}) {
    return (
        <ul className={`${className} grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 gap-2 md:gap-x-4 gap-y-2 font-normal`}>
            { items.map((item, index) => <li key={`${item} - ${index}`} className="pb-2  leading-tight border-b border-knorr">{item}</li>)}
        </ul>
    )
}


export const ListElementFragment = graphql`
    fragment ListElementFragment on ContentfulListElement {
        id
        name
        items
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
