import React from 'react'
import { graphql } from 'gatsby';
import { toKebabCase } from '../../utils/General';
import { addColour } from '../rich-text/RichText';

export default function SubHeaderElement({ text, noMenu = false, className = "text-knorr text-3xl md:text-5xl font-normal leading-tight" }) {


    const subHeaderId = toKebabCase(text.replace(/[0-9]/, ""))

    return (

        <h3  id={subHeaderId} data-menu={`${!noMenu ? "true" : "false"}`} data-sub="true" className={`${className}`}>
            {addColour([text])}
        </h3>
    )
}


export const SubHeaderElementFragment = graphql`
    fragment SubHeaderElementFragment on ContentfulSubHeaderElement {
        id
        text
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
