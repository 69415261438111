import React from 'react'


export default function LinkIcon(props) {
    return (
        <svg viewBox="0 0 100 125" x="0px" y="0px" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g stroke="current" strokeWidth="1" fill="current" fillRule="evenodd" className={props.className} transform="translate(0,-952.36218)">
                <path d="m 41.514525,1033.4747 c -6.231117,6.2311 -16.396203,6.2312 -22.627238,2e-4 -6.230946,-6.2309 -6.230876,-16.3961 2.32e-4,-22.6272 l 14.142127,-14.14213 c 5.940401,-5.94042 15.438297,-6.26847 21.721259,-0.86204 a 3.009094,3.0091504 45 0 1 -3.91113,4.57417 c -3.954098,-3.40229 -9.796144,-3.241 -13.567567,0.5304 l -14.14214,14.1421 c -3.954095,3.9541 -3.954108,10.1881 -1.38e-4,14.1421 3.954025,3.954 10.187926,3.9539 14.142006,-2e-4 L 50.70693,1015.797 a 3.0002437,3.0003 45 1 1 4.242614,4.2427 l -13.435019,13.435 z m 25.45584,-25.4558 c -5.940439,5.9403 -15.438208,6.2684 -21.721284,0.8619 a 3.0090969,3.0091533 45 1 1 3.911131,-4.5741 c 3.954079,3.4022 9.796105,3.2409 13.567565,-0.5304 l 14.142117,-14.14219 c 3.954066,-3.95408 3.954157,-10.18797 1.59e-4,-14.14197 -3.953993,-3.95399 -10.187933,-3.95393 -14.142003,1.4e-4 l -13.435029,13.43503 a 3.0002437,3.0003 45 1 1 -4.242559,-4.24256 l 13.435025,-13.43504 c 6.231095,-6.23109 16.396223,-6.23118 22.627205,-2.1e-4 6.231026,6.23103 6.230911,16.39616 -1.95e-4,22.62726 l -14.142132,14.14214 z" fill="current" fillOpacity="1" stroke="current" marker="none" visibility="visible" display="inline" overflow="visible" />
            </g>
        </svg>
    )
}

