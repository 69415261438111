import React from 'react'
import AssetElement from '../components/contentful-elements/AssetElement';
import ButtonElement from '../components/contentful-elements/ButtonElement';
import CarouselElement from '../components/contentful-elements/CarouselElement';
import GridImageElement from '../components/contentful-elements/GridImageElement';
import HeaderElement from '../components/contentful-elements/HeaderElement';
import ImageElement from '../components/contentful-elements/ImageElement';
import ListElement from '../components/contentful-elements/ListElement';
import PreHeaderElement from '../components/contentful-elements/PreHeaderElement';
import RichTextElement from '../components/contentful-elements/RichTextElement';
import SubHeaderElement from '../components/contentful-elements/SubHeaderElement';
import TwoImagesElement from '../components/contentful-elements/TwoImagesElement';
import PackagingStage from '../components/contentful-other/PackagingStage';
import CarouselSlide from '../components/contentful-other/CarouselSlide';
import QuestionAnswerElement from '../components/contentful-elements/QuestionAnswerElement';

import { getTypeFromSys } from './Mappers'
import ElementWrapper from '../components/general/ElementWrapper';

const elementsReferences = {
    assetElement: AssetElement,
    buttonElement: ButtonElement,
    carouselElement: CarouselElement,
    gridImageElement: GridImageElement,
    headerElement: HeaderElement,
    imageElement: ImageElement,
    listElement: ListElement,
    preHeaderElement: PreHeaderElement,
    richTextElement: RichTextElement,
    subHeaderElement: SubHeaderElement,
    twoImagesElement: TwoImagesElement,
    packagingStage: PackagingStage,
    carouselSlide: CarouselSlide,
    questionAnswerElement: QuestionAnswerElement
}


const createElements = (elements = []) => {
    return elements.map(element => createComponent(element))
}


const createComponent = (element) => {
    const Element = elementsReferences[element.type]

    const id = element._id
    // return a function so that it can be rendered with JSX
    // then pass the module in as an arg which is converted to props in the JSX

    // Handle rare errors of faulty components
    if (!element|| !Element) return {
        Element: () => <></>
    }
    // console.log(element.type, element)
    return {
        Element: () => Element({...element}),
        id,
    }
}

export const getElementType = (element) => {

    if (!element) return {}
    const isArray = Array.isArray(element)

    let types = {}
    if (isArray) {
        const mappedElements = element.map(element => {
            const mappedElement = mapElement(element)
            return types[mappedElement.type] = mappedElement
        } )
        return types
    } else {
        const mappedElement = mapElement(element)
        types[mappedElement.type] = mappedElement
        return types
    }
   
}

export const useElements = (elements, props) => {
    // Test to see if it is an array of objects
    const isArray = Array.isArray(elements)

    // If it isn't an array check that it isn't null (what is returned from contentful)
    if (!isArray && elements) {
        return () => <ElementWrapper 
            elements={createComponent(mapElement(elements, props))} 
        />
    }

    if (isArray) { 
        return () => <ElementWrapper elements={createElements(mapElements(elements, props))} />
    }

    // If it is neither return react fragment
    return () => <></>
}



const mapElements = (elements, props) => {
    return elements.map(element => mapElement(element, props))
}

const mapElement = (element, props) => {
    const type = getTypeFromSys(element.sys)

    const specificProps = getSpecificProps(props, type)
    const { sys, __typename, id, ...restOfElement } = element
    return {
        type: type,
        _id: id,
        ...restOfElement,
        ...specificProps
    }
}


const getSpecificProps = (props={}, type) => {


    const specificProps = props[type]

    if (specificProps) {
        return specificProps
    }
    
    return props
}

export default useElements